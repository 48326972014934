import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import Headings from "../components/headings"
import Hero from "../components/hero"
import BlocksRenderer from "../components/blocks-renderer"

const PrivacyPolicy = () => {
  const { strapiPrivacy } = useStaticQuery(graphql`
    query {
      strapiPrivacy {
        hero {
          ...StrapiHero
        }
        seo {
          ...StrapiSeo
        }
        headings {
          title
          description
        }
        blocks {
          ...Blocks
        }
      }
    }
  `)

  const { hero, headings, blocks, seo } = strapiPrivacy

  const structuredData = seo.structuredData

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "website",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={seo.metaImage.localFile.publicURL}
        post={false}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />

      <Hero hero={hero} />

      <section className="uk-section-default">
        <div className="uk-container uk-margin">
          <Headings title={headings.title} description={headings.description} />
          <BlocksRenderer blocks={blocks} />
        </div>
      </section>
    </>
  )
}

export default PrivacyPolicy
